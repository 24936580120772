import * as dompack from 'dompack';
import 'dompack/browserfix/baseline';
import 'dompack/browserfix/reset.css';

// whintegration returns config object with some handy site parameters like siteroot, imgroot, locale, ...
import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log

import './shared/forms/forms';
import './shared/rtd/rtd';
import './pages/search';
import './examplesite.scss';

import './widgets/cta';
import './widgets/video';
import './widgets/twocol';

//import './debug';//displays design grid if cols=12 is added to url

import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';

// open external links in new window - see https://code.webhare.com/publisher/utilities/linkhandler/
openLinksInNewWindow();

//Toggle panel with mobile navigation
dompack.register(".mobilenav-toggle", node => {
  node.addEventListener("click", ev => {
    document.documentElement.classList.toggle("showmobilemenu");
  });
});
