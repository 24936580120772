import "./search.scss";
import * as dompack from 'dompack';

dompack.register(".toggle-topsearch", node => {
  node.addEventListener("click", ev => {
    ev.preventDefault();
    document.documentElement.classList.toggle("show-topsearch");
  });
});

dompack.register("#searchform", node => {
  node.addEventListener("submit", ev => {
    if (!node.words.value)
      ev.preventDefault();
  });

  const params = new URLSearchParams(location.search);
  const words = params.get("words");
  if (words)
    node.words.value = words;
});
